import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/Manual_Recording/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import RecTable from 'components/Web_User_Interface/1440p_Series/Features/Manual_Recording/recTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Manual Video Recording",
  "path": "/Web_User_Interface/1440p_Series/Features/Manual_Recording/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Set the total duration and resolution for manual recordings here, which can be initiated via the quick access bar. Note that the preset lead time is included in the total recording duration.",
  "image": "./WebUI_1440p_SearchThumb_Features_Status_LEDs.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Manual Video Recording' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Set the total duration and resolution for manual recordings here, which can be initiated via the quick access bar. Note that the preset lead time is included in the total recording duration.' image='/images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/Manuelle_Aufnahme/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/Manual_Recording/' crumbLabel="Status LED" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4bc2c96ae128699959efd5ea4e844f3c/2e367/1440p_Settings_Features_Manual_Rec.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACEklEQVQoz2WQS2sTYRSG8z+6qdKkuVcUpM1kLl8mM5kk08y1TXMzraJVaxQrllotraCC4krcKLhQKF1pK1RXxeLGRRHBX+BPeWRSS0UXD+9ZHB7ec2L5fJ5sNks2lyM5dgqtvUr4eB/7/g6N9Y/U1nYxV3eo/CGardUPtJ9+Qe0+QJEmyeUnGBkZYXR0lFg6nSYik8kQHztNyW7SX35Ce2mDzo1N2n/oDB7+xSa9W4+wggUKkxJTUzLnzp4fEotEx8QTCTRFoj3rE7o2oWcP02/UcG0Lx67i1Cu49QpOtUzDrqMoJnJRoKllipJ20jAiHo+jaYJWu0OzOYcfhHi+z3TDoV6rYhk6pmkgLAt92iGYmcfSG2iqQJFLFAoKsVQqNWx3LBRC0Ol2aLXmCMMQz/OYnZmhtXCN1uIK/f5V7gQBg+4iC815up6NYWioso5UUI+Exw0TiQSyLOO6Lk7DwfNcgsDDc6bxL97FW35B78JtnhkmG8Jg0PMYXDGwTHV4tiz9IzyaU0xMnKGkGRh6mblmlVq1jGUItOIUulCo2yZr6/fY3XrJoK8jVAWjJFBl+eTkKCPGx8fJpLPDn0RYloFR1hCa4FJvlptL13n39g0/f3zj18E+r5YXUZUCpi4oFuX/GyaTSTKZ3FCmKgK9ZKCLEpf7XbZfP2dn7xPfD79y8H6Lw897bG+s4Ffk4W5RUvgN74dXS7kR2o8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4bc2c96ae128699959efd5ea4e844f3c/e4706/1440p_Settings_Features_Manual_Rec.avif 230w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/d1af7/1440p_Settings_Features_Manual_Rec.avif 460w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/7f308/1440p_Settings_Features_Manual_Rec.avif 920w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/730d0/1440p_Settings_Features_Manual_Rec.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4bc2c96ae128699959efd5ea4e844f3c/a0b58/1440p_Settings_Features_Manual_Rec.webp 230w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/bc10c/1440p_Settings_Features_Manual_Rec.webp 460w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/966d8/1440p_Settings_Features_Manual_Rec.webp 920w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/fbb05/1440p_Settings_Features_Manual_Rec.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4bc2c96ae128699959efd5ea4e844f3c/81c8e/1440p_Settings_Features_Manual_Rec.png 230w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/08a84/1440p_Settings_Features_Manual_Rec.png 460w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/c0255/1440p_Settings_Features_Manual_Rec.png 920w", "/en/static/4bc2c96ae128699959efd5ea4e844f3c/2e367/1440p_Settings_Features_Manual_Rec.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4bc2c96ae128699959efd5ea4e844f3c/c0255/1440p_Settings_Features_Manual_Rec.png",
              "alt": "Web User Interface - 1440p Series - Features Status LED",
              "title": "Web User Interface - 1440p Series - Features Status LED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <RecTable mdxType="RecTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      